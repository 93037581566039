import { useRoute } from 'vue-router'

export const useLinkActive = () => {
  const route = useRoute()

  // 現在のページとリンクの href を比較して一致するか判定
  const isLinkActive = (href: string) => {
    return route.path === href
  }

  // console.log(route.path)

  return { isLinkActive }
}
